import * as React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';

import {
  Layout,
  SEO,
  Hero,
  Banner,
  HeadingWithCopy,
  Contact,
} from '../components';
import { useGraphQL } from '../hooks';
import config from '../utils/config';

function IndexPage() {
  const { whatWeDoBannerImage } = useGraphQL();
  const { allClientsJson, allSafetyJson } = useStaticQuery(graphql`
    {
      allClientsJson {
        nodes {
          abbr
          id
          image {
            childImageSharp {
              fluid(maxWidth: 144, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          name
          url
        }
      }
      allSafetyJson {
        nodes {
          abbr
          id
          image {
            childImageSharp {
              fluid(maxHeight: 48, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          name
          url
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO
        title="Our Services | Aerial Fire Suppression"
        description={`${config.title} partners with government agencies and land managers to provide aerial firefighting and dust suppression services across the Asia Pacific region.`}
      />
      <HomeHero />
      <OurPrimaryService />
      <Safety logos={allSafetyJson.nodes} />
      <OurClients logos={allClientsJson.nodes} />
      <Banner
        image={whatWeDoBannerImage.childImageSharp.fluid}
        imgStyle={{ objectPosition: 'top' }}
      />
      <OtherServices />
      <Contact bgClassName="bg-grey" />
    </Layout>
  );
}

function HomeHero() {
  const { whatWeDoHeroImage } = useGraphQL();
  return (
    <Hero image={whatWeDoHeroImage.childImageSharp.fluid}>
      <h1 className="heading-1">What we do</h1>
    </Hero>
  );
}

function OurPrimaryService() {
  return (
    <HeadingWithCopy heading="Our Primary Service">
      <h2>Aerial Firefighting</h2>
      <p>
        We pride ourselves on providing safe, efficient and effective aerial
        fire suppression services using high-quality aircraft, flown by talented
        and experienced pilots. We support ground crews to attack fires early,
        protecting homes, nature, people and infrastructure.
      </p>
      <p>
        We hold an International Air Operators Certificate (AOC) and have
        experience working in numerous countries. We have both the capability
        and desire to provide our expert services globally. We are agile and
        flexible, enabling us to fit in seamlessly with both private and
        government operations around the world.
      </p>
      <p>
        We are highly experienced in operating with other aircraft and in
        complex missions. All safety requirements and standards are met,
        specific to the country we operate for.
      </p>
      <p>We can support you with your aerial firefighting needs.</p>
      <p>
        Please <Link to="/contact-us">get in touch with us</Link> to have a
        conversation.
      </p>
    </HeadingWithCopy>
  );
}

function Safety({ logos }) {
  return (
    <HeadingWithCopy logos={logos} onGrey>
      <h2>Safety</h2>
      <p>{config.title} have a zero-accident history.</p>
      <p>
        We deliver a safe, sustainable, superior fire suppression services
        utilising the latest technology and best practice while meeting the
        requirements of the regulatory bodies and government agencies.
      </p>
      <p>
        The most important factor of all is the safety of our people, the
        environment and communities we serve.
      </p>
      <h3>Accreditation, Memberships and Regulation</h3>
      <ul>
        <li>
          <a href="https://www.casa.gov.au">
            Civil Aviation Safety Authority (CASA)
          </a>
        </li>
        <li>
          <a href="https://www.afac.com.au/">
            National Council for Fire and Emergency Services (AFAC)
          </a>
        </li>
        <li>
          <a href="https://aaaa.org.au">
            Aerial Application Association of Australia (AAAA)
          </a>
        </li>
        <li>
          <a href="https://aaaa.org.au/aims-aerial-improvement-management-system/">
            Aerial Improvement Management System (AIMS)
          </a>
        </li>
      </ul>
    </HeadingWithCopy>
  );
}

function OurClients({ logos }) {
  return (
    <HeadingWithCopy logos={logos}>
      <h2>Our Clients</h2>
      <p>
        R&M Aviation partners with government agencies and land managers to
        provide aerial firefighting services across the Asia Pacific region.
      </p>
      <p>
        Our aerial fire suppression services offer the utmost in reliability and
        accuracy, ensuring the safety and protection of the environment and the
        communities affected.
      </p>
      <p>Here are the current clients we serve:</p>
      <ul>
        {logos.map(({ url, name }) => (
          <li key={url}>
            <a href={url}>{name}</a>
          </li>
        ))}
      </ul>
    </HeadingWithCopy>
  );
}

function OtherServices() {
  return (
    <HeadingWithCopy heading="Other Services">
      <h2>Dust Suppression</h2>
      <p>
        R&M Aviation partners with mining operators to provide aerial dust
        control and suppression services. Our service is designed to help mining
        operators reduce their environmental impact and ensure their operations
        adhere to environmental standards.
      </p>
    </HeadingWithCopy>
  );
}

export default IndexPage;
